// @flow
import React, { useState } from 'react'
import { useAtom } from 'jotai'
import {
  formSessionIdAtom,
  formSessionIsReceiptStepAtom,
  validFormVersionAtom,
} from '../../../App/Atoms'
import { rem, rgba } from 'polished'
import styled from 'styled-components'

import type { LinkViewModel } from '../../../types/LinkViewModel'
import type { ServiceLogoutViewModel } from '../../../types/ServiceLogoutViewModel'
import type { OverlayViewModel } from '../../../types/OverlayViewModel'

import Col from '../../../components/Grid/Col'
import Row from '../../../components/Grid/Row'
import Link from '../../../components/Link'
import Ribbon from '../../../components/Ribbon/Ribbon'
import ModalComponent from '../../../components/ModalComponent/ModalComponent'
import FormOverlay from '../../../components/Form/FormOverlay/FormOverlay'
import { getFontSize } from '../../../styles/style-helpers'
import { color } from '../../../styles/theme'
import { mediaOnly } from '../../../styles/media'
import { durations } from '../../../styles/animations'

type Props = {
  /** Different areas of the site */
  services?: Array<LinkViewModel>,
  logout?: ServiceLogoutViewModel,
  logoutOverlay?: OverlayViewModel,
  closeLabel?: string,
  userCanSaveDelete?: boolean,
  deleteOverlay?: OverlayViewModel,
}

const Wrapper = styled.nav`
  /* Do not show on kids/lgbt theme */
  display: ${({ theme }) =>
    theme.name === 'kids' ? 'none' : 'block'};

  grid-area: service-bar;
  background-color: ${color.white};
  border-bottom: ${({ theme }) => `1px solid ${rgba(theme.primary, 0.08)}`};
  z-index: 70;
  position: relative;
`

const ServiceRow = styled(Row)`
  height: ${rem(39)};
`
const ServiceLink = styled(Link)`
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  font-size: ${getFontSize('tiny')};
  font-weight: bold;
  margin-right: ${rem(24)};
  height: ${rem(39)};
  line-height: ${rem(39)};

  &:last-child {
    margin-right: 0;
  }
`

const LogoutLink = styled(Link)`
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  font-size: ${getFontSize('tiny')};
  font-weight: bold;
  margin-right: ${rem(24)};
  line-height: ${rem(39)};
  padding: 0 ${rem(16)};
  cursor: pointer;
  background: rgb(215, 235, 239);
  background: linear-gradient(
    180deg,
    rgba(215, 235, 239, 1) 0%,
    rgba(172, 207, 213, 1) 100%
  );

  &:last-child {
    margin-right: 0;
  }
`

const StyledButtonLink = styled(Link)`
  border-width: ${rem(2)};
  border-style: solid;
  color: ${({ theme }) => theme.buttonSecondaryColor};
  border-color: ${({ theme }) => theme.buttonSecondaryColor};
  min-width: ${rem(110)};
  text-align: center;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  padding: ${rem(12)} ${rem(32)} ${rem(11)};
  border-radius: ${rem(25)};
  transition: all ${durations.fast} ease-out;

  &:hover {
    color: ${({ theme }) => theme.buttonPrimaryColor};
    border-color: ${({ theme }) => theme.buttonSecondaryColor};
    background-color: ${({ theme }) => theme.buttonSecondaryColor};
  }
`

const StyledButtonLinkSave = styled(StyledButtonLink)`
  margin-right: 16px;
  ${mediaOnly.xs`
  margin-bottom: 32px;
  margin-right: 0px;
  `}
`

function ServiceBar({
  services,
  logout,
  logoutOverlay,
  closeLabel,
  userCanSaveDelete,
  deleteOverlay,
}: Props) {
  const [showOverlay, setShowOverlay] = useState(false)
  const [formSessionId] = useAtom(formSessionIdAtom) // get sessionId from questionaireHeader using 'jotai'
  const [isReceiptStep] = useAtom(formSessionIsReceiptStepAtom) // see if form is on receipt step (it has been submitted) - value comes from QuestionaireModule using 'jotai'
  const [validFormVersion] = useAtom(validFormVersionAtom)
  const sessionId = formSessionId || ''

  const handleOpen = () => {
    setShowOverlay(true)
  }

  const handleClose = () => {
    setShowOverlay(false)
  }

  const searchParamsSaveForm = new URLSearchParams({
    sessionId,
    deleteActiveForm: 'false',
  })

  const searchParamsDeleteForm = new URLSearchParams({
    sessionId,
    deleteActiveForm: 'true',
  })

  const hrefDelete =
    logout &&
    logout.logoutLink &&
    `${logout.logoutLink}?${searchParamsDeleteForm.toString()}`

  const hrefSave =
    logout &&
    logout.logoutLink &&
    `${logout.logoutLink}?${searchParamsSaveForm.toString()}`

  return (
    <Wrapper aria-label="service menu">
      <Ribbon marginBottom={false}>
        <ServiceRow middle>
          <Col xs align="right">
            <>
              {services &&
                services.length > 0 &&
                services.map((link, key) => (
                  <ServiceLink key={key} {...link}>
                    {link.label}
                  </ServiceLink>
                ))}
              {logout &&
              logout.logoutLink &&
              logoutOverlay &&
              deleteOverlay &&
              userCanSaveDelete &&
              !isReceiptStep ? (
                <>
                  <LogoutLink onClick={handleOpen}>{logout.label}</LogoutLink>
                  <ModalComponent
                    open={showOverlay}
                    closeText={closeLabel}
                    onClose={handleClose}
                  >
                    <FormOverlay
                      handleClose={handleClose}
                      title={
                        validFormVersion
                          ? logoutOverlay.title
                          : deleteOverlay.title
                      }
                      src={
                        validFormVersion ? logoutOverlay.src : deleteOverlay.src
                      }
                    >
                      {validFormVersion && (
                        <StyledButtonLinkSave href={hrefSave} target="_self">
                          {logoutOverlay.secondaryLabel}
                        </StyledButtonLinkSave>
                      )}
                      <StyledButtonLink href={hrefDelete} target="_self">
                        {logoutOverlay.primaryLabel}
                      </StyledButtonLink>
                    </FormOverlay>
                  </ModalComponent>
                </>
              ) : (
                logout && (
                  <LogoutLink
                    onClick={() => {
                      if (window.confirm(logout.confirmMessage)) {
                        window.open(logout.logoutLink, '_self')
                      }
                    }}
                  >
                    {logout.label}
                  </LogoutLink>
                )
              )}
            </>
          </Col>
        </ServiceRow>
      </Ribbon>
    </Wrapper>
  )
}

ServiceBar.displayName = 'ServiceBar'
ServiceBar.defaultProps = {}

export default ServiceBar
