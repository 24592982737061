// @flow
import * as React from 'react'
import styled from 'styled-components'
import root from 'window-or-global'

import type { LinkViewModel } from '../../types/LinkViewModel'
import type { MainMenuItem } from '../../types/MainMenuItem'
import type { ServiceLogoutViewModel } from '../../types/ServiceLogoutViewModel'
import type { PersonInfoViewModel } from '../../types/PersonInfoViewModel'
import type { OverlayViewModel } from '../../types/OverlayViewModel'

import HeaderDesktop from './HeaderDesktop/HeaderDesktop'
import HeaderDevice from './HeaderDevice/HeaderDevice'
import HeaderDeviceOverlay from './HeaderDeviceOverlay/HeaderDeviceOverlay'
import ServiceBar from './ServiceBar/ServiceBar'
import SkipToContentLink from './SkipToContentLink/SkipToContentLink'
import { ConfigContext } from '../../App/AppShell'
import AlertBanner from '../../components/AlertBanner/AlertBanner'
import PowerOfAttorneyBanner from '../../components/PowerOfAttorneyBanner/PowerOfAttorneyBanner'
import MediaQuery from '../../components/MediaQuery/MediaQuery'

export type Props = {
  /** Fields needed to implement search */
  search?: LinkViewModel,
  /** Name of the site */
  siteName?: string,
  /** The link to the homepage. Label will be the site name to display next to the logo */
  home?: LinkViewModel,
  /** Main navigation items */
  mainMenu?: Array<MainMenuItem>,
  /** Utility links like help and contact */
  serviceMenu?: Array<LinkViewModel>,
  /** Link to the alternate site (for børn <-> familieretshuset) */
  alternate?: LinkViewModel,
  secondAlternate?: LinkViewModel,
  menuCloseLabel?: string,
  logout?: ServiceLogoutViewModel,
  /** Message for alert banner */
  alertMessage?: string,
  /** Power of Attorney banner */
  personInfo: PersonInfoViewModel,
  description?: string,
  closeLabel?: string,
  saveButtonLabel?: string,
  cancelButtonLabel?: string,
  logoutButtonLabel?: string,
  /** Overlays for saving or deleting application form */
  saveOverlay: OverlayViewModel,
  deleteOverlay: OverlayViewModel,
  logoutOverlay: OverlayViewModel,
  /** See if user can save/delete form from current page  */
  userCanSaveDelete?: boolean,
}

type State = {
  overlayIsOpen: boolean,
  activeMenuKey?: number,
}

class Header extends React.Component<Props, State> {
  static displayName = 'Header'
  static contextType = ConfigContext

  state = {
    overlayIsOpen: false,
    activated: false,
    activeMenuKey: undefined,
  }

  menuItems = undefined

  componentDidMount() {
    if (root && root.document) {
      root.document.addEventListener('keyup', this.handleKeyDown, false)
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const currentProps = this.props
    const currentState = this.state
    let shouldUpdate = false

    if (currentProps !== nextProps) {
      shouldUpdate = true
    }

    if (!shouldUpdate && currentState !== nextState) {
      shouldUpdate = true
    }

    return shouldUpdate
  }

  componentWillUnmount() {
    if (root && root.document) {
      root.document.removeEventListener('keyup', this.handleKeyDown, false)
    }
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      this.handleOverlayClose()
    }
  }

  handleDesktopMenuItemHover = (itemKey: number) => {
    // if hovering on the same ACTIVE item do nothing
    if (this.state.activeMenuKey === itemKey) return null

    this.setState(state => ({
      overlayIsOpen: true,
      activeMenuKey: itemKey,
    }))

    if (root && root.document) {
      root.document.body.style.overflowY = 'scroll'
      root.document.body.style.position = 'fixed'
      root.document.body.style.width = '100%'
    }
  }

  handleOverlayToggle = () => {
    const { state } = this

    this.setState({
      overlayIsOpen: !state.overlayIsOpen,
    })

    if (root && root.document) {
      //THe condition below was inverted because at the time this code is runned,
      //the state `state.overlayIsOpen` was not changed to true yet, so it is still false.
      //And this was causing the scroll not to work after ther user opens and closed the mobile menu.
      if (!state.overlayIsOpen) {
        root.document.body.style.overflowY = 'scroll'
        root.document.body.style.position = 'fixed'
        root.document.body.style.width = '100%'
      } else {
        root.document.body.style.overflowY = 'initial'
        root.document.body.style.position = 'static'
        root.document.body.style.width = 'initial'
      }
    }
  }

  handleOverlayClose = () => {
    this.setState({
      overlayIsOpen: false,
      activeMenuKey: undefined,
    })

    if (root && root.document) {
      root.document.body.style.width = 'initial'
      root.document.body.style.overflowY = 'initial'
      root.document.body.style.position = 'static'
    }
  }

  render() {
    const {
      search,
      siteName,
      home,
      mainMenu,
      serviceMenu,
      alternate,
      secondAlternate,
      menuCloseLabel,
      logout,
      alertMessage,
      personInfo,
      description,
      closeLabel,
      saveButtonLabel,
      cancelButtonLabel,
      logoutButtonLabel,
      saveOverlay,
      deleteOverlay,
      logoutOverlay,
      userCanSaveDelete,
    } = this.props

    const { overlayIsOpen } = this.state

    return (
      <React.Fragment key="header">
        <SkipToContentLink />
        {((this.context.theme === 'main' || this.context.theme === 'lgbt') && serviceMenu.length > 0) && (
          <ServiceBar
            services={serviceMenu}
            logout={logout}
            logoutOverlay={logoutOverlay}
            closeLabel={closeLabel}
            userCanSaveDelete={userCanSaveDelete}
            deleteOverlay={deleteOverlay}
          />
        )}
        <MediaQuery query={`(min-width: 1150px)`} defaultMatches={false}>
          <StickyHeaderDesktop>
            <HeaderDesktop
              siteName={siteName}
              home={home}
              mainMenu={mainMenu}
              search={search}
              alternate={alternate}
              isOpen={this.state.overlayIsOpen}
              activeMenuKey={this.state.activeMenuKey}
              onHover={this.handleDesktopMenuItemHover}
              onClose={this.handleOverlayClose}
              closeLabel={menuCloseLabel}
              secondAlternate={secondAlternate}
            />
            {personInfo && (
              <PowerOfAttorneyBanner
                personInfo={personInfo}
                description={description}
                closeLabel={closeLabel}
                saveButtonLabel={saveButtonLabel}
                cancelButtonLabel={cancelButtonLabel}
                logoutButtonLabel={logoutButtonLabel}
                saveOverlay={saveOverlay}
                deleteOverlay={deleteOverlay}
                logout={logout}
                userCanSaveDelete={userCanSaveDelete}
              />
            )}
          </StickyHeaderDesktop>
        </MediaQuery>
        <MediaQuery query={`(max-width: 1149px)`} defaultMatches={false}>
          <>
            <StickyHeaderDevice>
              <HeaderDevice
                home={home}
                siteName={siteName}
                onMenuClick={this.handleOverlayToggle}
                search={search}
                isOpen={this.state.overlayIsOpen}
              />
              <HeaderDeviceOverlay
                isOpen={this.state.overlayIsOpen}
                onClose={this.handleOverlayClose}
                menu={mainMenu}
                searchLink={search}
                alternateLink={alternate}
                secondAlternate={secondAlternate}
                serviceMenu={serviceMenu}
              />
            </StickyHeaderDevice>
            {personInfo && (
              <StickyPowerOfAttorneyDevice overlayIsOpen={overlayIsOpen}>
                <PowerOfAttorneyBanner
                  personInfo={personInfo}
                  description={description}
                  closeLabel={closeLabel}
                  saveButtonLabel={saveButtonLabel}
                  cancelButtonLabel={cancelButtonLabel}
                  logoutButtonLabel={logoutButtonLabel}
                  saveOverlay={saveOverlay}
                  deleteOverlay={deleteOverlay}
                  logout={logout}
                  userCanSaveDelete={userCanSaveDelete}
                />
              </StickyPowerOfAttorneyDevice>
            )}
          </>
        </MediaQuery>
        <AlertBanner message={alertMessage} />
      </React.Fragment>
    )
  }
}

export default Header

const StickyHeaderDesktop = styled.header`
  grid-area: header;
  position: relative; /* Fallback for IE11 */
  position: sticky;
  top: 0;
  z-index: 60;

  @media (max-width: 1150px) {
    display: none;
  }
`

const StickyHeaderDevice = styled.header`
  grid-area: header;
  position: relative; /* Fallback for IE11 */
  position: sticky;
  top: 0;
  z-index: 60;

  @media (min-width: 1151px) {
    display: none;
  }
`

const StickyPowerOfAttorneyDevice = styled.header`
  grid-area: header;
  position: relative; /* Fallback for IE11 */
  position: sticky;
  top: 0;
  z-index: ${props => (props.overlayIsOpen ? '40' : '60')};

  @media (min-width: 1151px) {
    display: none;
  }
`
